import { initializeApp } from "firebase/app";
import { getFirestore, setDoc, doc, getDoc, collection, getDocs } from "firebase/firestore"

const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
};

initializeApp(firebaseConfig)
export const db = getFirestore();


export async function initUser(profile) {
    const USER = await getDoc(doc(db, "users", profile.userId));
    const PROGRESS = await getDoc(doc(db, "progress", profile.userId));
    if (!USER.exists()) {
        addNewUser(profile);
        return { profile, progress: {} }
    }
    else return {
        profile: USER.data(),
        progress: PROGRESS.data(),
    };

}

async function addNewUser(profile) {
    try {
        await setDoc(doc(db, "users", profile.userId), profile);
        await setDoc(doc(db, "progress", profile.userId), {});
    } catch (e) {
        console.error("Error adding document: ", e);
    }
}

export async function updateDbProgress(id, progress) {
    try {
        await setDoc(doc(db, "progress", id), progress);
    } catch (e) {
        console.error("Error adding document: ", e);
    }
}

export async function updateStage0Form(id, _form) {
    try {
        await setDoc(doc(db, "stage0", id), _form);
    } catch (e) {
        console.error("Error adding document: ", e);
    }
}

export async function updateForm(id, _form) {
    try {
        await setDoc(doc(db, "form", id), _form);
    } catch (e) {
        console.error("Error adding document: ", e);
    }
}

export async function isFormExist(id) {
    const FORM = await getDoc(doc(db, "form", id));
    return FORM.exists();
}

export async function downloadForm(id) {
    const querySnapshot = await getDocs(collection(db, "form"));
    const RETURN = [];
    querySnapshot.forEach((doc) => {
        RETURN.push(doc.data())
    });
    return RETURN

}

export async function downloadUser(id) {
    const querySnapshot = await getDocs(collection(db, "users"));
    const RETURN = [];
    querySnapshot.forEach((doc) => {
        RETURN.push(doc.data())
    });
    return RETURN

}
